








import { Component, Vue } from "vue-property-decorator";
import { DefaPageNotFound } from "@defa-as/components";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    DefaPageNotFound,
  },
})
export default class ViewNotFound extends Vue {
  get rootRoute() {
    return {
      name: ROUTE_NAMES.ROOT,
    };
  }
}
